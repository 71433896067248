import React from "react";
import styled from "styled-components";
import { Link as RebassLink } from "rebass";
import { Link as GatsbyLink } from "gatsby";
import colors from "../../utils/theme/colors";

const animationStyle = `
  :after {
    content: "";
    width: 0px;
    height: 2px;
    background-color: white;
    position: absolute;
    left: 0px;
    right: 0px;
    margin: 0 auto;
    top: 24px;
    transition: width 0.3s ease-in-out;
  }
  &:hover:after {
    width: 40px;
  }
`;

const Link = styled(({ color, animation, ...props }) => (
  <RebassLink
    as={props.to ? GatsbyLink : "a"}
    {...props}
    href={props.to || props.href}
  />
))`
  display: flex;
  cursor: pointer;
  transition: color 0.2s ease-in-out, border-color 0.2s ease-in-out;
  color: ${props => props.color};
  text-decoration: none;
  text-align: center;
  user-select: text;
  position: relative;
  ${props => (props.animation ? animationStyle : null)}
`;

Link.defaultProps = {
  color: colors.white,
  animation: false
};

export default Link;
