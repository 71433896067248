import * as React from "react";
import { Box, Flex } from "rebass";
import PropTypes from "prop-types";
import { useStaticQuery, graphql } from "gatsby";
import styled from "styled-components";
import InstagramIcon from "../../assets/icons/instagram.svg";
import FacebookIcon from "../../assets/icons/facebook.svg";
import YoutubeIcon from "../../assets/icons/youtube.svg";
import Link from "../Link";

const Wrapper = styled(Box)`
  transition: transform 0.3s ease-in-out;
  @media (min-width: 1280px) {
    &:hover {
      transform: translateY(-5px);
    }
  }
`;

const SocialIcons = ({ iconProps = {}, ...props }) => {
  const {
    site: {
      siteMetadata: { socialLinks }
    }
  } = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          socialLinks {
            url
          }
        }
      }
    }
  `);
  return (
    <Flex flexDirection="column" justifyContent="flex-end" {...props}>
      <Wrapper {...iconProps}>
        <Link href={socialLinks[7].url} target="_blank" rel="noreferrer">
          <InstagramIcon />
        </Link>
      </Wrapper>
      <Wrapper {...iconProps}>
        <Link href={socialLinks[0].url} target="_blank" rel="noreferrer">
          <FacebookIcon />
        </Link>
      </Wrapper>
      <Wrapper {...iconProps}>
        <Link href={socialLinks[8].url} target="_blank" rel="noreferrer">
          <YoutubeIcon />
        </Link>
      </Wrapper>
    </Flex>
  );
};

SocialIcons.propTypes = {
  iconProps: PropTypes.shape()
};

SocialIcons.defaultProps = {
  iconProps: {}
};

export default SocialIcons;
