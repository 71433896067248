import * as React from "react";
import { Image } from "rebass";
import GatsbyImage from "gatsby-image";
import * as PropTypes from "prop-types";

const ImageComponent = ({ width, ...props }) => (
  <Image
    as={GatsbyImage}
    width={width}
    css="
      outline: none; 
      height: auto;       
    "
    {...props}
  />
);

ImageComponent.propTypes = {
  width: PropTypes.arrayOf(PropTypes.number)
};

ImageComponent.defaultProps = {
  width: [1, 1, 1]
};

export default ImageComponent;
