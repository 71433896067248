import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Box, Flex } from "rebass";
import { createGlobalStyle } from "styled-components";
import Logo from "../../assets/icons/logo2.svg";

const SplashScreenGlobalStyles = createGlobalStyle`
  body {
    overflow: hidden;
  }
  #___gatsby {
    width: ${({ modalIsOpen }) => (modalIsOpen ? "100%" : "unset")};
    ${({ modalIsOpen }) =>
      modalIsOpen
        ? `
      @media (max-width: 1023px) and (orientation: landscape) {
      position: unset; 
      max-height: 700px;
      overflow: hidden;
    }
      `
        : null};
  }
`;

const SplashScreen = ({ finishAnimation, ...props }) => {
  const [loaded, setloaded] = useState(false);
  useEffect(() => {
    setloaded(true);
  }, [setloaded]);
  return (
    <Flex
      backgroundColor="#1E242F"
      width="100%"
      height="100vh"
      justifyContent="center"
      alignItems="center"
      {...props}
    >
      {finishAnimation ? null : <SplashScreenGlobalStyles />}
      <Box
        css={`
          opacity: ${loaded ? 1 : 0};
          transition: all 0.4s ease-in;
          max-width: 400px;
          @media (max-width: 1279px) {
            max-width: 300px;
          }
          @media (max-width: 767px) {
            max-width: 200px;
          }
        `}
      >
        <Logo width="100%" height="100%" />
      </Box>
    </Flex>
  );
};

SplashScreen.propTypes = {
  finishAnimation: PropTypes.bool.isRequired
};

export default SplashScreen;
