export default {
  primary: {
    borderColor: "#3E5164",
    color: "white",
    height: "40px",
    padding: "15px",
    background: "rgba(18, 42, 63, 0.9)",
    ":hover": {
      borderColor: "#687888",
      background: "rgba(34, 62, 86, 0.9)"
    }
  }
};
