const colors = {
  white: "#ffffff",
  black: "#000000",
  grey: "#F7F9FA",
  darkText: "#1E242F",
  lightText: "#535B66",
  secondary1: "#0A87BE",
  secondary2: "#2d2f46",
  secondary3: "#9596a8",
  secondary4: "#F3F7F9",
  primary1: "#ff053b",
  primary2: "#764abc",
  primary3: "#e4e8eb"
};

export default colors;
