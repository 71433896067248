import React, { useState } from "react";
import { useStaticQuery, graphql } from "gatsby";
import { Box, Flex, Button } from "rebass";
import { Input, Textarea } from "@rebass/forms";
import { trackCustomEvent } from "gatsby-plugin-google-analytics";
import Frame from "../Frame";
import Image from "../Image";
import { H3, P } from "../Typography";
import SocialIcons from "../SocialIcons";
import colors from "../../utils/theme/colors";

const Footer = () => {
  const {
    footerBgImage: {
      childImageSharp: { fluid }
    }
  } = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          title
        }
      }
      footerBgImage: file(relativePath: { eq: "images/bg-footer.png" }) {
        childImageSharp {
          fluid(quality: 100, maxWidth: 2000) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
    }
  `);

  const [formEmail, setFormEmail] = useState(null);

  return (
    <Frame
      as="footer"
      id="contactUs"
      pt={[6, 7, 9, null]}
      pb={[3, 7, 9, null]}
      style={{
        position: "relative",
        background:
          "linear-gradient(90deg, #0A1C2F 0%, #0A1C2F 26.8%, rgba(10, 28, 47, 0) 79.27%)"
      }}
    >
      <Image
        fluid={fluid}
        fadeIn
        durationFadeIn={1000}
        css={{
          width: "100%",
          zIndex: -1,
          left: "0px",
          top: "0px",
          minHeight: "591px",
          height: "100%",
          overflow: "hidden"
        }}
        style={{ position: "absolute" }}
      />
      <Flex
        justifyContent="space-between"
        flexDirection={["column", "row", null, null]}
        height="100%"
      >
        <Box sx={{ color: "white", maxWidth: "400px" }}>
          <H3 mb={2}>Contact us</H3>
          <P mb={4}>
            Please fill out the form below and someone will be in touch with you
            surprisingly fast.
          </P>
          <Box
            as="form"
            name="DashMediaContactForm"
            method="post"
            data-netlify="true"
            data-netlify-honeypot="bot-field"
            action="/"
          >
            <Flex mb={2}>
              <Input
                variant="primary"
                id="name"
                name="name"
                type="text"
                placeholder="Name"
                mr={2}
                required
                autoComplete="off"
                css={`
                  &::placeholder {
                    color: #7e92a6;
                  }
                  &:focus {
                    border-color: #687888;
                    background: rgba(34, 62, 86, 0.9);
                  }
                `}
              />
              <Input
                variant="primary"
                id="email"
                name="email"
                type="email"
                placeholder="Email"
                required
                autoComplete="off"
                css={`
                  &::placeholder {
                    color: #7e92a6;
                  }
                  &:focus {
                    border-color: #687888;
                    background: rgba(34, 62, 86, 0.9);
                  }
                `}
                onChange={e => {
                  setFormEmail(e.target.value);
                }}
              />
            </Flex>
            <Textarea
              id="message"
              variant="primary"
              name="message"
              placeholder="Your message"
              required
              height={150}
              mb={3}
              css={`
                font-family: Ubuntu, sans-serif;
                resize: none;
                &::placeholder {
                  color: #7e92a6;
                }
                &:focus {
                  border-color: #687888;
                  background: rgba(34, 62, 86, 0.9);
                }
              `}
            />
            <Input type="hidden" name="bot-field" />
            <Input
              type="hidden"
              name="form-name"
              value="DashMediaContactForm"
            />
            <Button
              onClick={() => {
                trackCustomEvent({
                  category: "FORM",
                  action: "SendContactUs",
                  label: formEmail
                });
              }}
              variant="formButton"
              css={`
                @media (min-width: 1280px) {
                  :hover {
                    background-color: white;
                    color: ${colors.darkText};
                    border: 2px solid white;
                  }
                }
              `}
              type="submit"
              name="submit contact form"
            >
              send
            </Button>
          </Box>
        </Box>
        <SocialIcons
          flexDirection={["row", "column", null, null]}
          justifyContent={["center", "flex-end", null, null]}
          alignItems="center"
          pt={[8, 0, 0, null]}
          iconProps={{
            mb: [0, "25px", null, null],
            mr: ["25px", 0, null, null],
            css: {
              ":last-child": {
                marginBottom: "0px",
                marginRight: "0px"
              }
            }
          }}
        />
      </Flex>
    </Frame>
  );
};

export default Footer;
