import React from "react";
import PropTypes from "prop-types";
import { Box } from "rebass";

const Frame = ({ children, ...props }) => (
  <Box {...props} width={1}>
    {children}
  </Box>
);

Frame.propTypes = {
  children: PropTypes.node.isRequired,
  px: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.arrayOf(PropTypes.number)
  ])
};

Frame.defaultProps = {
  px: [2, 6, 8, 11]
};

export default Frame;
