import colors from "./colors";
import fonts from "./fonts";
import forms from "./forms";
import buttons from "./buttons";

const theme = {
  breakpoints: ["768px", "1280px", "1920px"],
  fontSizes: [
    0,
    14,
    16,
    18,
    20,
    22,
    24,
    26,
    28,
    34,
    36,
    38,
    48,
    50,
    52,
    54,
    56,
    58,
    60,
    62,
    64,
    66,
    68,
    70,
    72,
    74,
    76,
    78,
    80
  ],
  fontWeights: [400, 700, 900],
  fonts,
  space: Array.from(Array(31).keys()).map(num => num * 10),
  colors,
  forms,
  buttons
};

export default theme;
