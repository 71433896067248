import React from "react";
import PropTypes from "prop-types";
import { ThemeProvider } from "emotion-theming";
import Layout from "./src/components/Layout";
import theme from "./src/utils/theme";

export const wrapRootElement = ({ element }) => (
  <ThemeProvider theme={theme}>{element}</ThemeProvider>
);

export const wrapPageElement = ({ element, props }) => (
  <Layout {...props}>{element}</Layout>
);

wrapRootElement.propTypes = {
  element: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired
};

wrapPageElement.propTypes = {
  element: PropTypes.node.isRequired,
  props: PropTypes.shape().isRequired
};
