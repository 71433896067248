import colors from "./colors";

const baseButton = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  overflow: "hidden",
  position: "relative",
  padding: "10px 45px",
  cursor: "pointer",
  color: colors.white,
  backgroundColor: colors.primary1,
  appearance: "none",
  textTransform: "uppercase",
  whiteSpace: "nowrap",
  border: "none",
  borderRadius: 0,
  outline: "none",
  userSelect: "none",
  zIndex: 1
};

export default {
  formButton: {
    borderColor: "white",
    color: "white",
    border: "2px solid",
    backgroundColor: "transparent",
    padding: "13px 80px",
    borderRadius: "0px",
    textTransform: "uppercase",
    transition: "all 0.2s ease-in",
    minHeight: "50px"
  },
  headerButton: {
    border: "2px solid white",
    padding: "13px 80px",
    borderRadius: "0px",
    textTransform: "uppercase",
    transition: "all 0.2s ease-in",
    minHeight: "50px",
    backgroundColor: "white",
    color: "darkText"
  },
  primary: {
    ...baseButton
  },
  primary2: {
    ...baseButton,
    color: colors.primary1,
    backgroundColor: "transparent",
    border: `2px solid ${colors.primary1}`
  },
  secondary: {
    ...baseButton,
    backgroundColor: "transparent",
    border: `2px solid ${colors.white}`
  }
};
