import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Box, Flex } from "rebass";
import GlobalStyles from "../../utils/theme/globalStyles";
import Footer from "../Footer";
import SplashScreen from "../SplashScreen";

const Layout = ({ children, path }) => {
  const [loaded, setloaded] = useState(false);

  useEffect(() => {
    const timeOut = fn => {
      setTimeout(() => {
        fn(true);
      }, 1000);
    };
    if (path === "/") {
      timeOut(setloaded);
    }
  }, [setloaded, path]);

  return (
    <Flex as="section" flexDirection="column" height="100%">
      {path === "/" ? (
        <SplashScreen
          finishAnimation={loaded}
          css={{
            position: "absolute",
            top: "0px",
            left: "0px",
            overflow: "hedden",
            zIndex: loaded ? "-1" : "100",
            opacity: loaded ? 0 : 1,
            transition: "all 0.4s ease-in"
          }}
        />
      ) : null}
      <GlobalStyles />
      <Box as="main" flexGrow={1}>
        {React.cloneElement(children, path === "/" ? { loaded } : null)}
      </Box>
      <Footer />
    </Flex>
  );
};
Layout.propTypes = {
  children: PropTypes.node.isRequired,
  path: PropTypes.string.isRequired
};

export default Layout;
