import React from "react";
import { Heading, Text } from "rebass";

export const H1 = props => (
  <Heading {...props} sx={{ textTransform: "uppercase" }} />
);

H1.defaultProps = {
  as: "h1",
  fontSize: [10, 18, 28, 28],
  lineHeight: "120%",
  fontFamily: "ubuntu",
  fontWeight: 700
};

export const H2 = props => <Heading {...props} />;

H2.defaultProps = {
  as: "h2",
  fontSize: ["30px", 18, 18, 18],
  lineHeight: "110%",
  fontFamily: "ubuntu",
  fontWeight: 700
};

export const H3 = props => <Heading {...props} />;

H3.defaultProps = {
  as: "h3",
  fontSize: [7, 10, 10, 10],
  lineHeight: "110%",
  fontFamily: "ubuntu",
  fontWeight: 700
};

export const BlogH3 = props => (
  <Heading {...props} css="text-transform: uppercase;" />
);

BlogH3.defaultProps = {
  as: "h3",
  letterSpacing: "1px",
  fontSize: [2, 3, 4, 4],
  fontWeight: 700
};

export const H4 = props => <Heading {...props} />;

H4.defaultProps = {
  as: "h4",
  fontSize: [2, 3, 4, 4],
  lineHeight: "120%",
  fontFamily: "ubuntu",
  fontWeight: 700
};

export const H5 = props => <Heading {...props} />;

H5.defaultProps = {
  as: "h5",
  fontSize: [2, 4, 4, 4],
  lineHeight: "150%",
  fontFamily: "ubuntu",
  fontWeight: 300
};

export const P = props => <Text {...props} />;

P.defaultProps = {
  as: "p",
  fontSize: [1, 2, 2, 2],
  lineHeight: "150%",
  fontFamily: "ubuntu",
  fontWeight: 300
};

export const BlogP = props => <Text {...props} />;

BlogP.defaultProps = {
  as: "p",
  letterSpacing: "1px",
  fontSize: [1, 1, 2]
};

export const Span = props => <Text {...props} />;

Span.defaultProps = {
  as: "span"
};
