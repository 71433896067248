import React from "react";
import { Facebook as FacebookIcon } from "styled-icons/boxicons-logos";
import { Twitter as TwitterIcon } from "styled-icons/boxicons-logos";
import { Linkedin as LinkedinIcon } from "styled-icons/boxicons-logos";
import { Reddit as RedditIcon } from "styled-icons/icomoon";
import { SocialPinterest } from "styled-icons/typicons";
import { Flipboard as FlipboardIcon } from "styled-icons/fa-brands";
// eslint-disable-next-line import/no-unresolved
import * as FeedlyIcon from "../../assets/icons/socials/feedly.svg";

export const Facebook = ({ ...props }) => <FacebookIcon {...props} />;
export const Twitter = ({ ...props }) => <TwitterIcon {...props} />;
export const Linkedin = ({ ...props }) => <LinkedinIcon {...props} />;
export const Reddit = ({ ...props }) => <RedditIcon {...props} />;
export const Pinterest = ({ ...props }) => <SocialPinterest {...props} />;
export const Flipboard = ({ ...props }) => <FlipboardIcon {...props} />;
export const Feedly = ({ ...props }) => <FeedlyIcon {...props} />;

export { default } from "./SocialIcons";
